export const state = () => ({})

export const mutations = {}

export const actions = {
  async index({ commit }, id) {
    const res = await this.$axios.get(`organizations/${id}/users`)
    return res
  },
  async store({ commit }, { id, users }) {
    console.log('stooore')
    const res = await this.$axios.post(`organizations/${id}/users`, {
      users,
    })
    return res
  },
  /*eslint-disable*/
  async update({ commit }, { organizationId, userId, payload }) {
    const res = await this.$axios.patch(
      `organizations/${organizationId}/users/${userId}`,
      { data: payload }
    )
    return res
  },
  async destroy({ commit }, { organizationId, userId }) {
    const res = await this.$axios.delete(
      `organizations/${organizationId}/users/${userId}`
    )
    return res
  },
}
