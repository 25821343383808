export const state = () => ({})

export const mutations = {}

export const actions = {
  async show({ commit }, id) {
    return await this.$axios.get(`invites/${id}`)
  },
  async confirm({ commit }, { inviteId, payload }) {
    console.log('🚀 ~ confirm ~ payload:', payload)

    return await this.$axios.post(`invites/${inviteId}/confirm`, payload)
  },
  async resend({ commit }, inviteId) {
    return await this.$axios.get(`invites/${inviteId}/resend`)
  },
}
