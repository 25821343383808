
export default {
  name: 'MainNavbar',
  computed: {
    items() {
      let baseItems = []

      if (!this.$auth.loggedIn) {
        return baseItems
      }
      if (this.$user.can(['support', 'liaison'])) {
        baseItems = [
          ...baseItems,
          {
            title: 'Organizations',
            action: 'mdi-cog-box',
            active: true,
            click: () => {
              this.$router.push('/organizations')
            },
          },
          {
            title: 'Users',
            action: 'mdi-account-multiple',
            active: true,
            click: () => {
              this.$router.push('/users')
            },
          },
          {
            title: 'Systems',
            action: 'mdi-server',
            active: true,
            click: () => {
              this.$router.push('/systems')
            },
          },
        ]
      }

      if (
        this.$user.can(['administrator', 'liaison', 'user-manager', 'support'])
      ) {
        baseItems = [
          ...baseItems,
          {
            title: 'My office(s)',
            action: 'mdi-domain',
            active: false,
            click: () => {
              this.$router.push('/dashboard/organizations')
            },
          },
        ]
      }

      return baseItems
    },
  },
}
