
export default {
  name: 'UserNavigationDrawer',

  methods: {
    goToDashboard() {
      if (this.$unleash.isEnabled('dashboard.launch.v2')) {
        return (window.location.href = `${this.$config.AD_FRONTEND_DASHBOARD_EXTERNAL_BASE_URL}/user-settings/profile`)
      }

      this.$router.push('/dashboard')
    },
  },
}
