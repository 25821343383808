import { UnleashClient } from 'unleash-proxy-client'

export default ({ $config }, inject) => {
  const config = {
    url: $config.FEATURE_FLAG_URL,
    clientKey: $config.FEATURE_FLAG_API_KEY,
    appName: 'office-manager',
  }
  const unleash = new UnleashClient(config)
  inject('unleash', unleash)

  unleash.start()

  unleash.on('ready', () => {
    inject('unleash', unleash)
  })
}
